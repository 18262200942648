import React, { useEffect } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useQueryParams } from 'hooks/useQueryParam';
import { applyForCard, getLoanOfferXSell } from 'thunks';
import { setCardData } from 'handlers/cardData';
import { trackConversionLead } from 'utils/analytics';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import StateContainer from 'components/StateContainer';
import { CurrentFlow } from 'enums/CurrentFlow';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { getCardData } from 'selectors/getCardData';
import { setOfferProgress } from 'handlers/loanOffer';
import { FlowComponentType } from 'routes/FlowRouter';
import { ApplyingResult } from 'enums/FlowNextResults';

import styles from './Applying.module.scss';

const Applying = ({ handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { cardColor, borrowerCredentials, initialReferrer, referredBy } = useSelector(getCardData);
  const { entityId } = useSelector(getMethodAuthData);

  useEffect(() => {
    const apply = async () => {
      let cardAppliedResponse;
      try {
        cardAppliedResponse = await dispatchWithUnwrap(
          applyForCard({
            firstName,
            lastName,
            credentials: borrowerCredentials!,
            email,
            phoneNumber,
            cardColor: cardColor!,
            methodEntityId: entityId,
            utm: getUtmTagsVariables(params),
            httpReferrer: initialReferrer,
            cardReferredBy: referredBy,
          }),
        );
      } catch (error) {
        handleNext(ApplyingResult.Error);
        return;
      }

      if (cardAppliedResponse.applied) {
        dispatch(setCardData(cardAppliedResponse));
        if (cardAppliedResponse.debtConsolidationPossible) {
          handleNext(ApplyingResult.Success);
        } else {
          handleNext(ApplyingResult.NoDebtConsolidation);
        }
      }

      if (cardAppliedResponse.borrowerId) {
        analytics.identify(cardAppliedResponse.borrowerId);
        (window as any).nid('setUserId', cardAppliedResponse.borrowerId);
      }

      trackConversionLead({
        email,
        firstName,
        lastName,
        phoneNumber,
      });

      // Run x-sell loan offer for card application
      // Get loan offer gets data from current application
      // and labels it as a x-sell loan offer
      try {
        await dispatch(
          getLoanOfferXSell({
            applicationId: cardAppliedResponse.applicationId,
            flow: CurrentFlow.FinancialCheckup,
            updateCallback: (data) => {
              dispatch(setOfferProgress(data));
            },
          }),
        );
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    };

    apply();
  }, []);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="Applying..." />
    </div>
  );
};

export default Applying;
